import React, {useEffect, useState} from "react";
import TopBanner from "../partials/TopBanner";
import SideBar from "../partials/SideBar";
import NavBar from "../partials/NavBar";
import Terminal from "../partials/Terminal";
import * as Constants from "../Constants";

const EncryptText = () => {
    const[line, setLine] = useState("...");
    const[outputColor, setOutputColor] = useState("text-red-500");
    const [form, setForm] = useState({ encrypt: true, text: "",  method: "1", result: "Expect results here" });
    const [token, setToken] = useState("");
    const SERVER = Constants.SERVER;
    const [autoEncrypt, setAutoEncrypt] = useState(false);
    useEffect(()=>{
        if(localStorage.getItem("api_token")){
            setToken(localStorage.getItem("api_token"));
        }else{
            window.location.replace("/login");
        }
    },[]);
    function shouldDecrypt(value){
        let uniCount=0;
        for(let i=0;i<value.length;i++){
            if(value.charCodeAt(i)>127){
                uniCount++;
            }
        }
        return uniCount>value.length/10;
    }

    function appendLine(l) {
        setLine(l+"\n"+line);
    }

    function inputChanged(event) {
        //setForm({ ...form, [event.target.name]: event.target.value });
        let value=event.target.value;
        console.log("input changed",value);
        if(shouldDecrypt(value)){
            setForm({ encrypt: false, text: value, method: "1", result: "" });
        }else{
            setForm({ encrypt: true, text: value, method: "1", result: "" });
        }
    }

    function encryptText(event) {
        event.preventDefault();
        console.log("encrypting text",form.text);
        if(form.encrypt) {
            let endpoint=SERVER;
            if(autoEncrypt)
                endpoint+="/v1/encrypt/auto";
            else
                endpoint+="/v1/encrypt/generic";
            fetch(endpoint, {
                method: "POST",
                body: JSON.stringify({
                    "text": form.text,
                }),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            })
                .then(response => response.json())
                .then(data => {
                    console.log("encrypt-RESPONSE", data);
                    if(data.status==="success"){
                        console.log("RESULT UPDATED !!!!!!!!!!!!!!!!");
                        appendLine("Cipher Text: "+data.cipher_text);
                    }
                })
                .catch(error => {
                    console.log("ENCRYPT error", error);
                });
        }else{
            let endpoint=SERVER;
            if(autoEncrypt)
                endpoint+="/v1/decrypt/auto";
            else
                endpoint+="/v1/decrypt/generic";
            fetch(endpoint, {
                method: "POST",
                body: JSON.stringify({
                    "cipher_text": form.text,
                }),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            })
                .then(response => response.json())
                .then(data => {
                    console.log("encrypt-RESPONSE", data);
                    if(data.status==="success"){
                        appendLine("Decrypted: "+data.text);
                    }
                })
                .catch(error => {
                    console.log("ENCRYPT error", error);
                });
        }


    }

    function clearTerminal() {
        setLine("");
    }

    return(
        <div className="App">
            <div className="container-scroller">
                <TopBanner/>
                <SideBar/>
                <div className="container-fluid page-body-wrapper">
                    <NavBar/>
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-12 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">Encrypt Generic Data</h4>
                                            <p className="card-description"> You can encrypt any string with any length </p>
                                            <form className="forms-sample" onSubmit={encryptText}>

                                                <div className="form-group">
                                                    <label htmlFor="txtInput">Input</label>
                                                    <textarea onInput={inputChanged} className="form-control h-100 text-white" id="txtInput"
                                                              rows="10"></textarea>
                                                </div>
                                                    <div className="form-group form-check">
                                                        <label className="form-check-label w-50 px-60 text-white ">
                                                            <input type="checkbox" className="form-check-input" id="cboIsAuto" checked={autoEncrypt} onChange={(event)=>{
                                                                setAutoEncrypt(event.target.checked);
                                                            }}/>
                                                            Auto Encrypt Personal Data Only?
                                                            <i className="input-helper"></i></label>
                                                    </div>
                                                <button type="submit" className="btn btn-primary me-2">{form.encrypt ? "Encrypt":"Decrypt"}</button>
                                                <button type="reset" className="btn btn-dark">Clear</button>
                                            </form>
                                            <Terminal line={{line: line, color: outputColor}}/>
                                            <button onClick={clearTerminal} className="btn btn-primary text-2xl">Clear Terminal</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <footer className="footer">
                            <div className="d-sm-flex justify-content-center justify-content-sm-between">
                                <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © bootstrapdash.com 2021</span>
                                <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"> Free <a
                                    href="https://www.bootstrapdash.com/bootstrap-admin-template/" target="_blank">Bootstrap admin template</a> from Bootstrapdash.com</span>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default EncryptText;