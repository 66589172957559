import React, {useState, useEffect} from "react";
import TopBanner from "../partials/TopBanner";
import SideBar from "../partials/SideBar";
import NavBar from "../partials/NavBar";
import DirViewer from "../partials/DirViewer";
import {toast, ToastContainer} from "react-toastify";
import CloudFolder from "../partials/CloudFolder";
import * as Constants from "../Constants";
import {useParams} from "react-router-dom";
import {GoogleLogin, GoogleOAuthProvider, useGoogleLogin} from "@react-oauth/google";

const ECSMIDCloud = () => {

    const params = useParams();


    const [currentProviderName, setCurrentProviderName] = useState('Google Drive');
    const [currentPath, setCurrentPath] = useState([]);
    const toastId = React.useRef(null);
    const notify = (msg, options = {}) => toastId.current = toast(msg, options);
    const dismiss = () => toast.dismiss(toastId.current);
    const [token, setToken] = useState("");
    const [files, setFiles] = useState(
        []);
    const [mainInit, setMainInit] = useState(false);
    const [userID, setUserID] = useState("");
    const [userHash, setUserHash] = useState("");
    const [authorized, setAuthorized] = useState(false);
    const [loadingData, setLoadingData] = useState("");
    const [pathStack, setPathStack] = useState(['root']);
    const [redirect, setRedirect] = useState("");
    const SERVER = Constants.SERVER;
    const FILE_TYPES = {
        IMAGE: 0,
        VIDEO: 1,
        AUDIO: 2,
        EXCEL: 3,
        WORD: 4,
        PDF: 5,
        TEXT: 6,
        ZIP: 7,
        OTHER: 8
    }
    useEffect(() => {
        if (localStorage.getItem("api_token"))
            setToken(localStorage.getItem("api_token"));
        // window.main();
        setMainInit(!mainInit);
    }, []);

    function setDriveFileTypes(fileList) {
        const temp = [];
        for (let i = 0; i < fileList.length; i++) {
            let f = fileList[i];
            let file = {};
            if (f.mimeType === 'application/vnd.google-apps.folder') {
                file.isFile = false;
            } else {
                file.isFile = true;
                switch (f.mimeType) {
                    case 'application/vnd.google-apps.document':
                        file.type = FILE_TYPES.WORD;
                        break;
                    case 'application/vnd.google-apps.spreadsheet':
                        file.type = FILE_TYPES.EXCEL;
                        break;
                    case 'application/vnd.google-apps.presentation':
                        file.type = FILE_TYPES.OTHER;
                        break;
                    case 'application/vnd.google-apps.drawing':
                        file.type = FILE_TYPES.OTHER;
                        break;
                    case 'application/vnd.google-apps.script':
                        file.type = FILE_TYPES.TEXT;
                        break;
                    case 'application/vnd.google-apps.form':
                        file.type = FILE_TYPES.TEXT;
                        break;
                    case 'application/vnd.google-apps.map':
                        file.type = FILE_TYPES.OTHER;
                        break;
                    case 'application/vnd.google-apps.photo':
                        file.type = FILE_TYPES.IMAGE;
                        break;
                    case 'application/vnd.google-apps.video':
                        file.type = FILE_TYPES.VIDEO;
                        break;
                    case 'application/vnd.google-apps.audio':
                        file.type = FILE_TYPES.AUDIO;
                        break;
                }
            }
            if(f.hasOwnProperty('appProperties')){
                console.log("appProperties found",f.appProperties);
                file.encrypted=f.appProperties.hasOwnProperty("isEncrypted") && f.appProperties.isEncrypted==='true'
            }else
                file.encrypted = false;
            file.name = f.name;
            file.path = f.id;
            file.children = [];
            file.progress = 0;
            temp.push(file);
        }
        console.log("setDriveFileTypes", temp);
        setFiles([...temp]);
        setCurrentPath([...temp]);
    }

    function readDrive(values) {
        console.log('readDrive', values);
        /*let files=[];
        for(let i=0;i<values.length;i++){
            let file=values[i];
            let path=file.path;
            let name=file.name;
            let isFile=file.isFile;
            let encrypted=file.encrypted;
            let children=file.children;
            files.push({path,name,isFile,children,encrypted});
        }
        setFiles([...files]);*/
    }

    function readDriveData() {
        setLoadingData("visually-hidden");
        let hash = userHash ? userHash : localStorage.getItem("user_hash");
        let endpoint = Constants.SERVER + "/v1/drive/read/" + hash + "/na";
        fetch(endpoint, {
            headers: {
                "Authorization": "Bearer " + (token.length > 0 ? token : localStorage.getItem("api_token")),
            }
        })
            .then((result) => result.json())
            .then((result) => {
                console.log("response/read/drive", result);
                setLoadingData("");
                if (result.status === 'success') {
                    let values = result.values;
                    //readDrive(values);
                    setDriveFileTypes(values);
                } else {
                    notify(result.message);
                }
            }).catch((err) => console.log(err));
    }

    useEffect(() => {
        console.log('#####>Params changed: ', params);
        if (localStorage.getItem("api_token")) {
            setToken(localStorage.getItem("api_token"));
            setUserHash(localStorage.getItem("user_hash"));
        } else {
            window.location.replace('/login');
        }
        if (params.hasOwnProperty("provider")) { //replaced with user id instead
            setCurrentProviderName(params.provider);
            setAuthorized(true);
            readDriveData();
        }

    }, [params]);

    function initializeSelf() {
        //setCurrentPath([...files]);
    }

    useEffect(() => {
        if (token.length > 0) {
            initializeSelf();
        }
    }, [token]);

    function decryptFile(fileID) {
        console.log('decryptFile', fileID);
        const endpoint = Constants.SERVER + "/v1/drive/decrypt/" + userHash + "/" + fileID;
        updateFileProgress(fileID, Math.floor(Math.random() * 50) + 25);
        fetch(endpoint, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
            .then((result) => result.json())
            .then((result) => {
                console.log('response/encrypt', result);
                updateFileProgress(fileID, 100);
                if (result.status === 'success') {
                    notify(result.message);
                } else {
                    notify(result.message);
                }
                setTimeout(()=>{
                    updateFileProgress(fileID, 0);
                    updateFileStatus(fileID, false);
                },1500);
            })
            .catch((err) => console.log(err));
    }

    function updateFileStatus(fileID, encrypted) {
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            if (file.path === fileID) {
                file.encrypted = encrypted;
                break;
            }
        }
        setFiles([...files]);
    }

    function updateFileProgress(fileID, progress) {
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            if (file.path === fileID) {
                file.progress = progress;
                break;
            }
        }
        setFiles([...files]);
    }

    function encryptFile(fileID) {
        console.log('encryptFile', fileID);
        const endpoint = Constants.SERVER + "/v1/drive/encrypt/" + userHash + "/" + fileID;
        updateFileProgress(fileID, Math.floor(Math.random() * 50) + 25);
        fetch(endpoint, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
            .then((result) => result.json())
            .then((result) => {
                console.log('response/encrypt', result);
                updateFileProgress(fileID, 100);
                if (result.status === 'success') {
                    notify(result.message);
                } else {
                    notify(result.message);
                }
                setTimeout(()=>{
                    updateFileProgress(fileID, 0);
                    updateFileStatus(fileID, true);
                },1500);
            })
            .catch((err) => console.log(err));
    }

    function onAction(fileID, isFile, isLocked) {
        console.log('action', fileID, isFile, isLocked);
        if (isFile) {
            if (isLocked) {
                //decrypt
                decryptFile(fileID);
            } else {
                encryptFile(fileID);
            }
        } else {
            //browse forward

        }
    }

    function back() {
        if (pathStack.length > 1) {
            pathStack.pop();
            let path = pathStack[pathStack.length - 1];
            setPathStack([...pathStack]);
            descend(path, path.isFile, false);
        }
    }

    const render = content => (
        <CloudFolder id={content.folder.path} name={content.folder.name}
                     onClick={(e) => descend(content.folder.path, content.folder.isFile)} isFile={content.folder.isFile}
                     isLocked={content.folder.encrypted} onAction={onAction}
                     progress={Math.floor(content.folder.progress)}></CloudFolder>
    )

    function descend(pathID, isFile, descend = true) {
        console.log('#####> dir changed to ', pathID, isFile);

        if (!isFile) {
            const endpoint = Constants.SERVER + "/v1/drive/list/" + userHash + "/" + pathID;
            fetch(endpoint, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then((response) => response.json())
                .then((response) => {
                    console.log('response/list/drive', response);
                    if (response.status === 'success') {
                        if (descend === true) {
                            pathStack.push(pathID);
                            setPathStack([...pathStack]);
                        }
                        setDriveFileTypes(response.values);
                    } else {
                        notify(response.message);
                    }
                })
                .catch((err) => console.log(err));
        } else {
            //create draggable content for file
            //start drag on mouse click
        }
    }


    function oauthDrive(userID) {
        //oauth call
        let endpoint = SERVER + "/v1/drive/read/" + userID + "/na";
        fetch(endpoint, {
            headers: {
                "Authorization": "Bearer " + (token.length > 0 ? token : localStorage.getItem("api_token")),
            }
        }).then((response) => response.json())
            .then((response) => {
                console.log('response', response);
                if (response.status === 'success') {
                    setRedirect(response.redirect);
                }
            })
            .catch((err) => console.log(err));
    }

    useEffect(() => {
        if (redirect.length > 0) {
            window.location.replace(redirect);
        }
    }, [redirect]);

    function driveLogin() {
        oauthDrive(userHash);
    }

    return (
        <div className="App">
            <div className="container-scroller">
                <TopBanner/>
                <SideBar/>
                <div className="container-fluid page-body-wrapper">
                    <NavBar/>
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="page-header">
                                <h3 className="page-title"> ECSMID Cloud </h3>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="#">ECSMID</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Google Drive</li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="row">

                                <div className="col-10 grid-margin stretch-card"
                                     style={{display: authorized ? "none" : "block"}}>
                                    <div className="card">
                                        <div className="card-body place-content-start">
                                            <div
                                                className="place-content-start align-content-start justify-content-start">
                                                <h4 className="align-self-start align-content-start justify-content-start align-items-start p-4 text-2xl">Sign
                                                    in with your cloud storage provider</h4>

                                                <div className="w-25 p-2 mx-0">
                                                    <button className='btn btn-primary place-content-start p-2.5 w-100'
                                                            onClick={(e) => driveLogin()}><i
                                                        className="mdi mdi-google-drive text-xl"></i>Sign in with Google
                                                        Drive
                                                    </button>
                                                    {/*<GoogleOAuthProvider clientId={Constants.DRIVE_CLIENT_ID}>
                                                            <GoogleLogin
                                                                onSuccess={credentialResponse => {
                                                                    console.log(credentialResponse);
                                                                }}
                                                                onError={() => {
                                                                    console.log('Login Failed');
                                                                }}
                                                            />
                                                        </GoogleOAuthProvider>*/}
                                                </div>
                                                <div className="w-25 p-2 mx-0">
                                                    <button
                                                        className='btn btn-primary place-content-start p-2.5 w-100 justify-content-start align-content-start'
                                                        disabled={true}>
                                                        <i className="mdi mdi-dropbox text-xl p-2"></i>Sign in with
                                                        Dropbox
                                                    </button>
                                                </div>
                                                <div className="w-25 p-2 mx-0">
                                                    <button className='btn btn-primary place-content-start p-2.5 w-100'
                                                            disabled={true}>
                                                        <i className="mdi mdi-amazon-drive text-xl"></i>Sign in with AWS
                                                        S3
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body place-content-start">

                                            <div style={{display: authorized ? "block" : "none"}}>
                                                <h4 className="card-title">{currentProviderName}</h4>
                                                <button onClick={(e) => back(false)}
                                                        className='btn btn-primary place-content-start pr-5 m-2'>Back
                                                </button>
                                                <DirViewer path={currentPath} render={render} isRemote={false}/>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <footer className="footer">
                            <div className="d-sm-flex justify-content-center justify-content-sm-between">
                                <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © bootstrapdash.com 2021</span>
                                <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"> Free <a
                                    href="https://www.bootstrapdash.com/bootstrap-admin-template/" target="_blank">Bootstrap admin template</a> from Bootstrapdash.com</span>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </div>
    )
}
export default ECSMIDCloud;