import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";
import EncryptText from "./pages/EncryptText";
import EncryptFile from "./pages/EncryptFile";
import ECSMIDFTP from "./pages/ECSMIDFTP";
import Documentation from "./pages/Documentation";
import Datashield from "./pages/Datashield";
import Login from './pages/Login';
import DatashieldAI from "./pages/DatashieldAI";
import ECSMIDCloud from "./pages/ECSMIDCloud";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
     <Routes>
         <Route path="/encrypt-text" element={<EncryptText />} />
         <Route path="/encrypt-file" element={<EncryptFile />} />
         <Route path="/ecsmid-ftp" element={<ECSMIDFTP />} />
         <Route path="/documentation" element={<Documentation />} />
         <Route path="/datashield/:sheetID/:credential" element={<DatashieldAI />} />
         <Route path="/datashield/:sheetID" element={<DatashieldAI />} />
         <Route path="/datashield" element={<DatashieldAI />} />
         <Route path="/ecsmid-cloud" element={<ECSMIDCloud />}/>
         <Route path="/ecsmid-cloud/:provider" element={<ECSMIDCloud />}/>
         <Route path="/login" element={<Login />}/>
         <Route path="/" element={<App />} />
         <Route path="*" element={<h1 className="display-5 text-red-500">404<br/>Not Found</h1>}/>
     </Routes>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
